/* Next */
import Link from 'next/link';
import Image from 'next-image-export-optimizer';

/* Material */
import Container from '@mui/material/Container';
import useStyles from './styles'; // Stelle sicher, dass dieser Pfad korrekt ist

/* Images */
import Logo from '../logos/logo.svg';

/* Config */
import shopInfos from 'template/infos';

const Header: React.FC = () => {
  const classes = useStyles(); // Nutze die Styles aus styles.ts
  
  return (
    <div className={classes.headerWrapper}>
      <Container maxWidth='lg' className={classes.topContainer}>
        <Link href='/' legacyBehavior>
          <a>
            <Image
              src={Logo}
              alt={shopInfos.name}
              width={201}
              height={120}
              unoptimized
            />
          </a>
        </Link>
      </Container>
    </div>
  );
}

export default Header;
