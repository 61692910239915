import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerWrapper: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(0),
      backgroundColor: '#fce4de',
    },
    topContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start', // Links ausrichten für Desktop-Ansicht
      [theme.breakpoints.down('sm')]: { // Wende Styles für Mobilgeräte an
        justifyContent: 'center' // Zentrieren auf Mobilgeräten
      }
    },
  })
);

export default useStyles;
