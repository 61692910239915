const infos = {

  // Shop-Variablen
  title: 'hellomom',
  name: 'hellomom.de',
  url: 'https://shop.hellomom.de/',
  email: 'hallo@hellomom.de',
  //telephone: '0202/43044332',
  telephone: undefined as string | undefined,
  fax: '0202/43044350',
  publicher: 'hellomom',
  priceRange: '$', // Wie Teuer sind wir?   1-5  // 1 = Billig  2 = Normal  5 = SUPER-TEUER!!!

  // SHOP INFOS
  description: 'Finden Sie in unserem Shop schöne Dekoartikel für Ihr Zuhause!',
  titleAlternate: 'hellomom',
  host: 'shop.hellomom.de',
  locale: 'de_DE',
  currency: 'EUR',

  // LEGAL INFOS
  legalowner: 'Angelina Minardi',
  legalname: 'hellomom.de',
  street: 'Simonsstr. 80',
  postalcode: '42117',
  locality: 'Wuppertal',
  region: 'Nordrhein-Westfalen',
  regionCode: 'DE-NW',
  countryCode: 'DE',

  registergericht: null as string | null, // 'Amtsgericht ???'
  registernummer: null as string | null, // 'HRB ?????'
  umsatzsteuernr: 'DE 355655005',

  // Konfiguration
  paymentAfterCheckout: false as boolean, // true = Zahlung nach Bestellung, false = Zahlung vor Bestellung

} as const

export default infos
